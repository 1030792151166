body {
  font-family: 'Poppins' 'Work Sans', sans-serif;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Work Sans Semi Bold';
  src: url('/src/assets/fonts/WorkSans-SemiBold.ttf');
}
@font-face {
  font-family: 'Work Sans Bold';
  src: url('/src/assets/fonts/WorkSans-Bold.ttf');
}

@font-face {
  font-family: 'Work Sans';
  src: url('/src/assets/fonts/WorkSans-Regular.ttf');
}

.StripeElement {
  display: block;
  width: 280px;
  font-family: 'Work Sans';
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: #f0f0f0;
}
@media (min-width: 770px) {
  .StripeElement {
    width: 540px;
  }
}

.PaymentRequestButton {
  padding: 0;
  margin-bottom: 20px;
}

/* Compassion Quiz */
.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 0.3s;
}

.fade-leave {
  position: absolute;
  top: 0;
  margin-top: 100px;
  opacity: 1;
}

@media (min-width: 768px) {
  .fade-leave {
    margin-top: 165px;
  }
}
/* need to handle desktop */

.fade-leave.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

/* Loading animation */
.loading-animation {
  position: relative;
  background: #ffc942;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 10px solid #ffdf8f;
}

.loading-animation:after {
  content: '';
  background: trasparent;
  width: 140%;
  height: 140%;
  position: absolute;
  border-radius: 100%;
  top: -20%;
  left: -20%;
  opacity: 0.7;
  box-shadow: rgba(245, 175, 0, 0.6) -4px -5px 3px -3px;
  -webkit-animation: rotate 2s infinite linear;
          animation: rotate 2s infinite linear;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
  }
}

